import React from "react";
import styled, { css } from "styled-components";
import { DialogTitle, Icon, Link, MenuItem, Select } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";

import { app } from "js/namespaces";
import BeautifulLogo from "js/react/components/BeautifulLogo";
import { Gap10, Gap30 } from "js/react/components/Gap";
import ProgressBar from "js/react/components/ProgressBar";
import Spinner from "js/react/components/Spinner";
import { Divider } from "js/react/components/UiComponents";
import { withFirebaseUser } from "js/react/views/Auth/FirebaseUserContext";

import { CheckoutDialog } from "./CheckoutDialog";
import { BeautifulLogoContainer, BodySmall, ContentContainer, FormContainer, HeaderContainer, Heading, ProgressBarContainer, ScrollContainer } from "./FormStyling";
import PaymentForm from "./PaymentForm";

import "css/billing.scss";

const TRIAL_TIME = 14;

const LabelLarge = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 145%;
  letter-spacing: -0.28px;
  margin: 0;
  `;

const Container = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const TermsofUseLink = styled(Link)`
    &&& {
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 145%;
        letter-spacing: -0.28px;
        text-decoration-line: underline;
        color: #11a9e2;
    }`;

const TermsOfUseAndRefundPolicy = () => (
    <span>
        <TermsofUseLink target="_blank" href="https://www.beautiful.ai/terms-of-service">Terms of Service</TermsofUseLink>.
    </span>
);

const RowFlex = css`
    display: flex;
    flex-direction: row;
`;

const Row = styled.div`
    ${RowFlex};
`;

const PriceComparisonContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const PriceComparisonRow = styled.div`
    ${RowFlex};
    justify-content: space-between;
`;

const AmountPaidContainer = styled.div`
    ${RowFlex};
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
`;

const AmountSavedContainer = styled.div`
    ${RowFlex};
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
`;

const StrikedAmount = styled.span`
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.18px;
    text-decoration-line: line-through;
    color: #666;
`;

const Badge = styled.div`
    background: #96BB47;
    color: white;
    padding: 3px 6px;
    display: flex;
    gap: 10px;
    align-items: flex-start;
    text-transform: uppercase;
    border-radius: 1px;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.28px;
`;

const ChangePlanSection = styled.span`
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.28px;
    color: #666;

    @media (max-width: 768px) {
        flex: 1 1 auto;
    }
`;

const StyledLink = styled(Link)`
    &&& {
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 145%;
        letter-spacing: -0.28px;
        text-decoration-line: underline;
        color: #11a9e2;
    }
`;

const StyledCancelAnytimeText = styled.span`
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    letter-spacing: -0.28px;
    margin-top: 18px;
`;

const StyledCancelAnytimeTextBold = styled.span`
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
    letter-spacing: -0.28px;
`;

const StyledRow = styled(Row)`
    margin-top: 10px;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

class CheckoutSignup extends CheckoutDialog {
    constructor(props) {
        super(props);
    }

    get billingInterval() {
        const { billingInterval } = this.state;
        return billingInterval;
    }

    get nextBillingDate() {
        const { billingInterval: billingIntervalState, hasTakenTrial } = this.state;

        //
        if (hasTakenTrial) {
            if (billingIntervalState === "year") {
                return moment()
                    .add(1, "year")
                    .format("MMMM Do, YYYY");
            }

            return moment()
                .add(1, "month")
                .format("MMMM Do, YYYY");
        }

        return this.trialExpirationDate;
    }

    get totalYearlyOnMonthlySubscription() {
        const { prices } = this.state;
        return prices.month.unit_amount * 12 / 100;
    }

    toggleBillingInterval = () => {
        const { billingInterval } = this.state;
        this.setState({ billingInterval: billingInterval === "month" ? "year" : "month" });
    };

    calculateTotalPayment = (isYearly, trial = false) => {
        const { prices } = this.state;

        if (trial) return 0;

        return isYearly ? prices.year.unit_amount / 100
            : prices.month.unit_amount / 100;
    }

    payPerMonth = isYearly => {
        const { prices } = this.state;
        return isYearly ? prices.year.unit_amount / 12 / 100
            : prices.month.unit_amount / 100;
    }

    get totalSavings() {
        return 100 - Math.round(((this.calculateTotalPayment(true)) / (this.totalYearlyOnMonthlySubscription)) * 100);
    }

    yearComparison = isYearly => {
        const { productDisplayName, billingInterval, taxAmount } = this.state;

        const taxTodayString = (taxAmount && !this.showTrial) ? " plus tax" : "";
        const taxNextBillingCycleString = taxAmount ? " plus tax" : "";

        return (
            <>
                <PriceComparisonContainer>
                    <PriceComparisonRow>
                        <BodySmall>
                            {isYearly ? "" : "Monthly"} {productDisplayName} Plan
                        </BodySmall>
                        <AmountPaidContainer>
                            {isYearly && <StrikedAmount>
                                ${this.totalYearlyOnMonthlySubscription}
                            </StrikedAmount>}
                            <BodySmall style={{ fontWeight: 400 }}>
                                ${this.calculateTotalPayment(isYearly)} / {billingInterval}
                            </BodySmall>
                        </AmountPaidContainer>
                    </PriceComparisonRow>
                    <PriceComparisonRow>
                        <AmountSavedContainer>
                            {isYearly && <StrikedAmount>
                                ${this.payPerMonth(false)}
                            </StrikedAmount>}
                            <BodySmall style={{ fontWeight: 400 }}>
                                ${isYearly
                                    ? `${this.payPerMonth(isYearly)} x 12 months`
                                    : `${this.payPerMonth(isYearly)} x 1 month`}
                            </BodySmall>
                        </AmountSavedContainer>
                        {isYearly && <Badge>save {this.totalSavings}%</Badge>}
                    </PriceComparisonRow>
                </PriceComparisonContainer>
                <Divider margin={15} color="#CCC" />
                <PriceComparisonContainer>
                    <PriceComparisonRow>
                        <BodySmall>
                            Due today
                        </BodySmall>
                        <BodySmall>
                            ${this.calculateTotalPayment(isYearly, this.showTrial)}{taxTodayString}
                        </BodySmall>
                    </PriceComparisonRow>
                    {this.showTrial && (<PriceComparisonRow>
                        <BodySmall style={{ fontWeight: 400, color: "#666" }}>
                            Due {this.nextBillingDate}
                        </BodySmall>
                        <BodySmall style={{ fontWeight: 400, color: "#666" }}>
                            ${this.calculateTotalPayment(isYearly)}{taxNextBillingCycleString}
                        </BodySmall>
                    </PriceComparisonRow>)}
                </PriceComparisonContainer>
            </>);
    }

    paymentDetailsExplanation = () => {
        const text = "Your subscription will automatically";

        return (<StyledCancelAnytimeText>
            {text}&nbsp;
            <StyledCancelAnytimeTextBold>
                renew {this.billingInterval === "year" ? "annually" : "monthly"}.&nbsp;
            </StyledCancelAnytimeTextBold>
            <TermsOfUseAndRefundPolicy />
        </StyledCancelAnytimeText>);
    }

    get modalTitle() {
        const { hasTakenTrial } = this.state;
        return hasTakenTrial ? "Beautiful.ai Pro" : `Start your Beautiful.ai ${TRIAL_TIME}-day trial`;
    }

    get submitLabel() {
        const { hasTakenTrial } = this.state;
        return hasTakenTrial ? "Get started" : "Start Free Trial";
    }

    render() {
        const { successfulPurchase, prices, productDisplayName, alreadySubscribed, isSubmitting } = this.state;

        if (!prices || alreadySubscribed) {
            return null;
        }

        const fullPrice = this.amount;
        const price = Math.max(fullPrice - (this.state.availableReferralCredit / 100), 0);
        const priceId = this.state.prices[this.billingInterval]?.id;

        if (successfulPurchase) {
            return this.getSuccessfulPurchaseDialog(productDisplayName);
        }

        const Content = this.showTrial ? (
            <>
                <Heading>
                    {this.modalTitle}
                </Heading>
                <Gap30 />
                <div
                    id="checkout_form_container"
                    className={classNames({
                        disabled: isSubmitting
                    })}
                >
                    {isSubmitting && <Spinner />}
                    {this.yearComparison(this.billingInterval === "year")}
                    {this.billingInterval === "month" &&
                        <>
                            <Gap10 />
                            <Row><StyledLink
                                isYearly={false}
                                component="button"
                                variant="body2"
                                onClick={this.toggleBillingInterval}
                            >Save {this.totalSavings}% with annual billing</StyledLink>
                            </Row>
                        </>}

                    <Gap30 />
                    {priceId && <PaymentForm
                        gapElement={<Gap10 />}
                        priceId={priceId}
                        customerType="individual"
                        onSuccess={this.handleOnSuccess}
                        onBeforeSubmit={this.handleOnBeforeCharge}
                        onFailure={this.handleOnFailure}
                        submitLabel={this.submitLabel}
                        buttonsContainerStyle={{ marginTop: 12, justifyContent: "flex-start" }}
                        hasTakenTrial={!this.showTrial}
                        onPromoChanged={this.handlePromoChanged}
                        onTaxCalculated={this.handleTaxCalculated}
                        paymentDetails={<>
                            {this.paymentDetailsExplanation()}

                            {this.billingInterval === "year" &&
                                <>
                                    <StyledRow>
                                        <ChangePlanSection>
                                            Pay ${this.payPerMonth(false)}/mo (${this.totalYearlyOnMonthlySubscription}/yr) when you&nbsp;
                                        </ChangePlanSection>
                                        <StyledLink
                                            isYearly={true}
                                            component="button"
                                            onClick={this.toggleBillingInterval}
                                            style={{ textAlign: "left" }}
                                        >switch to monthly billing.</StyledLink>
                                    </StyledRow>
                                </>
                            }
                        </>
                        }
                    />}
                </div>
            </>
        ) : (<>
            <DialogTitle style={{
                padding: "0 0 5px",
            }}>
                Upgrade to Beautiful.ai <span color="#11a9e2">{productDisplayName}</span>.
            </DialogTitle>
            <div
                id="checkout_form_container"
                className={classNames({
                    disabled: isSubmitting
                })}
            >
                {isSubmitting && <Spinner />}
                <LabelLarge style={{ color: "#666" }}>
                    We're sorry but you are not eligible for a 14-day free trial because you have
                    already had a trial in the past.
                </LabelLarge>
                <Gap30 />
                {prices.month && (
                    <>
                        <div style={{
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "120%", /* 21.6px */
                            color: "#222",
                            marginBottom: 18,
                        }}>Choose Plan</div>

                        <Select
                            SelectDisplayProps={{ style: { textTransform: "capitalize" } }}
                            style={{ width: "100%" }}
                            variant="outlined"
                            value={this.billingInterval}
                            inputProps={{ className: "select-input" }}
                            onChange={event => {
                                this.setState({
                                    billingInterval: event.target.value
                                });
                            }}
                        >
                            <MenuItem className="menu-item-with-badge" value="year">
                                Annual Plan - ${prices.year.unit_amount / 12 / 100}/month
                                <div className="menu-item-badge">Best Value</div>
                            </MenuItem>
                            <MenuItem value="month">Monthly Plan - ${prices.month.unit_amount / 100}/month</MenuItem>
                        </Select>
                    </>
                )}

                {!prices.month && (
                    <b>Annual Plan - ${prices.year.unit_amount / 12 / 100}/month</b>
                )}

                <LabelLarge style={{
                    color: "#666",
                    marginTop: 12
                }}>
                    You will be charged <strong>${price.toFixed(2)}</strong> today. Your next bill will be due on{" "}
                    <strong>{this.nextBillingDate}</strong>.&nbsp;
                    {<TermsOfUseAndRefundPolicy />}
                </LabelLarge>

                {this.state.availableReferralCredit > 0 && (
                    <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <Icon style={{ color: "#fa0", fontSize: "1.25em", verticalAlign: "sub" }}>monetization_on</Icon>{" "}
                        You saved <b>${Math.min(this.state.availableReferralCredit / 100, fullPrice)}</b> from referral credit.
                    </div>
                )}
                <Gap30 />

                {priceId && <PaymentForm
                    priceId={priceId}
                    customerType="individual"
                    onSuccess={this.handleOnSuccess}
                    onBeforeSubmit={this.handleOnBeforeCharge}
                    onFailure={this.handleOnFailure}
                    submitLabel="Upgrade Now"
                    submitButtonStyle={{ marginTop: 30 }}
                    hasTakenTrial={true}
                    onPromoChanged={this.handlePromoChanged}
                    onTaxCalculated={this.handleTaxCalculated}
                />}
            </div>
        </>
        );

        return (<Container>
            <HeaderContainer>
                <BeautifulLogoContainer>
                    <BeautifulLogo darkOnLight={!app.isDarkMode} forceMarketing width={132.855} />
                </BeautifulLogoContainer>
                <ProgressBarContainer>
                    <ProgressBar progress={100} prevProgress={80} />
                </ProgressBarContainer>
            </HeaderContainer>
            <ScrollContainer>
                <ContentContainer >
                    <FormContainer>
                        {Content}
                    </FormContainer>
                </ContentContainer>
            </ScrollContainer>
        </Container>);
    }
}

export default withFirebaseUser(CheckoutSignup);
